/*
Flowing text animation
*/
.title-word {
  animation: color-animation 4s linear infinite;
}

.title-word-1 {
  --color-1: #DF8453;
  --color-2: #3D8DAE;
  --color-3: #E4A9A8;
}

.title-word-2 {
  --color-1: #DBAD4A;
  --color-2: #ACCFCB;
  --color-3: #17494D;
}

.title-word-3 {
  --color-1: #ACCFCB;
  --color-2: #E4A9A8;
  --color-3: #ACCFCB;
}


@keyframes color-animation {
  0%    {color: var(--color-1)}
  32%   {color: var(--color-1)}
  33%   {color: var(--color-2)}
  65%   {color: var(--color-2)}
  66%   {color: var(--color-3)}
  99%   {color: var(--color-3)}
  100%  {color: var(--color-1)}
}

/* Here are just some visual styles. 🖌 */



.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.95vw;
  text-transform: uppercase;
}

.title2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 3vw;
  text-transform: uppercase;
}


.animate-character
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #1DB954 0%,
    #191414  29%,
    #FFFFFF 67%,
    #282828  100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /*text-fill-color: transparent;*/
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 25px;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/*
Col-md-3 bordering and height params
*/
.col-md-3 {
  height: 600px;
  border-right: 4px solid black;
}

.col-md-9 {
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
}

p {
  font-size: 24px;
  text-align: center;
}

.dot-1, .dot-2, .dot-3 {
  animation: dot-animation 1.5s infinite;
  animation-delay: 0s, 0.5s, 1s;
}

@keyframes dot-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.75s, visibility 0.75s;
}

.loader-hidden {
  opacity: 0;
  visibility: hidden;
}

.loader::after {
  content: "";
  width: 75px;
  height: 75px;
  border: 15px solid #FFFFFF;
  border-top-color: #1DB954;
  border-radius: 50%;
  animation: loading 0.75s ease infinite;
}

@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

